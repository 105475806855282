import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { values } from 'lodash/fp'
import * as sentryClient from 'sentry/client'
import { useSentryUser } from 'sentry/useSentryUser'
import { TooltipProvider } from 'ui/components/ui/tooltip'
import { Provider } from 'react-redux'
import { getStore } from 'store'
import { useWlsyToolbar } from 'toolbar/src/common/hooks/useWlsyToolbar'
import { useAuthCookieWatcher } from 'auth/hooks/useAuthCookieWatcher'
import { ErrorBoundaryLayout } from 'error-kit/ErrorBoundaryLayout'
import { RouterErrorPage } from 'error-kit/RouterErrorPage'
import config from './config'
import { ErrorPage } from 'error-kit/ErrorPage'
import useWatchLogout from '../features/auth/hooks/useWatchLogout'
import { setRoutes } from 'router/routes'
import useFetchEmojis from 'components/emojis/hooks/useFetchEmojis'
import { useGetApiV1InstanceQuery } from 'instanceinfo'
import useGetNotificationsCount from 'notifications/hooks/useGetNotificationsCount'

const { isDev, sentry } = config()

const loadPage = (name: string) => async () => {
  const page = (await import(`./pages/${name}/index.tsx`)) as {
    [key: string]: React.FC
  }
  return { Component: page.default }
}

export const mainRoutesList = [
  '/',
  '/tooter',
  '/tooter/lists',
  '/tooter/bookmarks',
  '/tooter/mentions',
  '/tooter/likes',
  '/tooter/live',
  '/tooter/following',
  '/tooter/notifications',
] as const

const mainRoutes = mainRoutesList.reduce((acc, route) => {
  const newRoute = {
    [route]: {
      path: route,
      lazy: loadPage('Home'),
    },
  }

  return { ...acc, ...newRoute }
}, {})

export const routes = {
  post: {
    path: '/:username/posts/:postId',
    lazy: loadPage('Post'),
  },
  posts: {
    path: '/:username/posts',
    lazy: loadPage('UserPosts'),
  },
  profile: {
    path: '/:username/:tab?',
    lazy: loadPage('UserProfile'),
  },
  404: {
    path: '/404',
    element: <ErrorPage error={404} />,
  },
  notFound: {
    path: '/*',
    element: <ErrorPage error={404} />,
  },
  ...mainRoutes,
} as const

setRoutes(routes)

export type TooterRoutes =
  | (typeof routes)[keyof typeof routes]['path']
  | (typeof mainRoutesList)[number]

export const router = createBrowserRouter([
  {
    element: <ErrorBoundaryLayout />,
    errorElement: <RouterErrorPage />,
    children: values(routes),
  },
])

sentryClient.init(sentry)

const Hooks = () => {
  useAuthCookieWatcher()
  useWlsyToolbar({ isDev })
  useWatchLogout()
  useSentryUser()
  useFetchEmojis()
  useGetApiV1InstanceQuery()
  useGetNotificationsCount({
    status: 'UNREAD',
  })

  return null
}

export const App = () => (
  <Provider store={getStore()}>
    <TooltipProvider>
      <Hooks />
      <RouterProvider router={router} />
    </TooltipProvider>
  </Provider>
)
